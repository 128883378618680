import { graphql, useStaticQuery } from "gatsby";

export type WpUser = GatsbyTypes.WpUserQuery["allWpUser"]["nodes"];

/* All the users will be authors. This is because WordPress just provides the public users, and those are the authors */
export const useAllUsers = (): WpUser => {
  const users = useStaticQuery<GatsbyTypes.WpUserQuery>(graphql`
    query WpUser {
      allWpUser {
        nodes {
          ...WpUserFields
        }
      }
    }
  `);

  return users.allWpUser.nodes;
};
