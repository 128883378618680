import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useRVOAuthorTemplateStyles = makeStyles((theme: Theme) => {
  return createStyles({
    wrapper: {
      width: "100%",
    },
    authorCardWrapper: {
      marginTop: theme.spacing(-8),
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(-3),
      },
    },

    titlePosts: {
      textAlign: "center",
    },
  });
});
