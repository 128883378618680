import { createStyles, makeStyles } from "@material-ui/core";

export const useRVOBlogGridStyles = makeStyles(() =>
  createStyles({
    wrapper: {},
    cardWrapper: {
      height: "100%",
    },
    textCenter: {
      textAlign: "center",
    },
  })
);
