import {
  PostTypeInProject,
  urlPrefixMap,
} from "../../../../config/wordPressConfig";

export const authorNameToPath = (name: string): string => {
  const pathAuthorName = name.split(" ").join("-").toLowerCase();
  const pathAuthorNameNormalized = pathAuthorName
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
  return pathAuthorNameNormalized;
};

export const pathForSingleUser = (
  postType: PostTypeInProject,
  name: string
): string => {
  const prefix = urlPrefixMap[postType];
  const urlComponents: string[] = [];
  const pathAuthorName = authorNameToPath(name);

  if (prefix) {
    urlComponents.push(prefix);
  }
  urlComponents.push(`autor/${pathAuthorName}`);

  return `/${urlComponents.join("/")}`;
};
