import { authorNameToPath } from "../../helpers/serverSide/pageCreation/pathForSingleUser";
import { useAllUsers } from "./useAllUsers";

export const useWpUserByName = (
  authorNamePath: GatsbyTypes.Maybe<string>
): GatsbyTypes.WpUserFieldsFragment | undefined => {
  const allUsers = useAllUsers();
  const singleUser = allUsers.find(
    // This eslint-disable is used because the name of the author is not null
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    (user) => authorNameToPath(user.name!) === authorNamePath
  );

  return singleUser;
};
