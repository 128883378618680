import { Box, Typography } from "@material-ui/core";
import { PageProps } from "gatsby";
import { useMemo } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { BlogPost } from "../../../interfaces/Blog";
import { useAllFundamentals } from "../../../queries/wpFundamentals/useAllFundamentals";
import { mapSingleWpPostToPost } from "../../../queries/wpPost/mapSingleWpPostToPost";
import { useAllPosts } from "../../../queries/wpPost/useAllPosts";
import { mapSingleWpUserToUser } from "../../../queries/wpUser/mapSingleWpUserToUser";
import { useWpUserByName } from "../../../queries/wpUser/useWpUserByName";
import RVOAuthorCard from "../../02-molecules/RVOAuthorCard/RVOAuthorCard";
import RVOContainer from "../../02-molecules/RVOContainer/RVOContainer";
import { RVOBackgroundGradient } from "../../02-molecules/RVOContainer/RVOContainerInterfaces";
import RVOBlogGrid from "../../03-organisms/RVOBlogGrid/RVOBlogGrid";
import { useRVOAuthorTemplateStyles } from "./RVOAuthorTemplateStyles";

const RVOAuthorTemplate = ({ pageContext }: PageProps): JSX.Element => {
  const classes = useRVOAuthorTemplateStyles();
  const { t } = useTranslation();
  const { authorName } = pageContext as { authorName: string };
  const currentAuthor = mapSingleWpUserToUser(useWpUserByName(authorName));

  const posts = useAllPosts();
  const fundamentals = useAllFundamentals();

  const allPosts = useMemo(() => {
    return [...posts, ...fundamentals];
  }, [posts, fundamentals]);

  const authorsPosts: BlogPost[] | null = useMemo(
    () =>
      currentAuthor
        ? allPosts
            .map(mapSingleWpPostToPost)
            .filter((post) => post.author.id === currentAuthor.id)
        : null,
    [allPosts, currentAuthor]
  );

  const seoTitle = currentAuthor?.name
    ? t("seo.author.title", { authorName: currentAuthor.name })
    : t("seo.author.titleFallback");
  const seoDescription = currentAuthor?.name
    ? t("seo.author.description", { authorName: currentAuthor.name })
    : t("seo.author.descriptionFallback");

  return (
    <>
      <Helmet title={seoTitle}>
        <meta name={seoDescription} content="" />
      </Helmet>

      <Box className={classes.wrapper}>
        <RVOContainer
          background={RVOBackgroundGradient.SecondaryLightToMediumBlue}
          fullWidth={true}
          paddingBottom={true}
          // This eslint-disable is necessary because this container is empty
          // eslint-disable-next-line react/no-children-prop
          children={""}
        />

        <Box className={classes.authorCardWrapper}>
          <RVOContainer noPaddingTop={true}>
            <RVOAuthorCard
              author={currentAuthor}
              typographyProps={{ variant: "h1" }}
            />
          </RVOContainer>
        </Box>

        <RVOContainer paddingBottom={true}>
          <Typography variant="h2" className={classes.titlePosts}>
            {t("authorPage.postsBy")}

            {currentAuthor?.name}
          </Typography>

          {authorsPosts && <RVOBlogGrid blogs={authorsPosts} />}
        </RVOContainer>
      </Box>
    </>
  );
};

export default RVOAuthorTemplate;
