import { Grid, Typography } from "@material-ui/core";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import RVOBlogCard from "../../02-molecules/RVOBlogCard/RVOBlogCard";
import { RVOBlogGridProps } from "./RVOBlogGridInterfaces";
import { useRVOBlogGridStyles } from "./RVOBlogGridStyles";

const RVOBlogGrid = (props: RVOBlogGridProps): JSX.Element => {
  const classes = useRVOBlogGridStyles();
  const { t } = useTranslation();
  const { blogs, multigrid } = props;

  return blogs.length > 0 ? (
    <Grid container={true} spacing={2} className={classes.wrapper}>
      {blogs.map((blog, index) => {
        return (
          <Grid
            key={blog.id}
            item={true}
            xs={12}
            sm={6}
            md={multigrid ? 4 : 6}
            lg={multigrid ? 3 : 4}
            xl={multigrid ? 3 : 3}
          >
            <motion.div
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                delay: index * 0.1,
              }}
              className={classes.cardWrapper}
            >
              <RVOBlogCard blog={blog} shortExcert={multigrid} />
            </motion.div>
          </Grid>
        );
      })}
    </Grid>
  ) : (
    <Typography variant="h2" className={classes.textCenter}>
      {t("blogGrid.noBlogsFound")}
    </Typography>
  );
};

export default RVOBlogGrid;
