import { graphql, useStaticQuery } from "gatsby";

export type WpPost = GatsbyTypes.WpPostQuery["allWpPost"]["nodes"];

export const useAllPosts = (): WpPost => {
  const posts = useStaticQuery<GatsbyTypes.WpPostQuery>(graphql`
    query WpPost {
      allWpPost(sort: { fields: date, order: DESC }) {
        nodes {
          ...WpPostFields
        }
      }
    }
  `);

  return posts.allWpPost.nodes;
};
