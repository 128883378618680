import { graphql, useStaticQuery } from "gatsby";

export type FundamentalsFromQuery =
  GatsbyTypes.wpFundamentalQuery["allWpFundamental"]["nodes"];

export const useAllFundamentals = (): FundamentalsFromQuery => {
  const fundamentalPosts =
    useStaticQuery<GatsbyTypes.wpFundamentalQuery>(graphql`
      query wpFundamental {
        allWpFundamental {
          nodes {
            ...WpFundamentalsFields
          }
        }
      }
    `);
  return fundamentalPosts.allWpFundamental.nodes;
};
