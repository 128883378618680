import { createStyles, makeStyles, Theme } from "@material-ui/core";

const authorImageSize = 9;
export const useRVOAuthorCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    authorCard: {
      width: "70%",
      marginTop: theme.spacing(2 + 1 + authorImageSize / 2),

      overflow: "visible",
      [theme.breakpoints.only("sm")]: {
        width: "80%",
      },
      [theme.breakpoints.only("xs")]: {
        width: "100%",
      },
    },
    authorCardMargin: {
      margin: "0 auto",
    },
    authorCardContent: {
      [theme.breakpoints.only("xs")]: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
      },
    },
    authorCardContentGrid: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    authorImageWrapper: {
      overflow: "visible",
      marginBottom: theme.spacing(2), // padding card
      marginTop: theme.spacing(-(2 + 1 + authorImageSize / 2)), // -(padding card + padding content card + (height image / 2))rem
    },
    authorImage: {
      width: theme.spacing(authorImageSize),
      height: theme.spacing(authorImageSize),
    },
    authorName: {
      textAlign: "center",
    },
    authorNameNoMarginBottom: {
      marginBottom: theme.spacing(1),
    },
    authorJob: {
      marginBottom: theme.spacing(2.813),
    },
    authorDescription: {
      textAlign: "center",
    },
    authorLink: {
      marginTop: theme.spacing(2),
    },
  })
);
