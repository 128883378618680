import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { clsx } from "clsx";
import { Button } from "gatsby-material-ui-components";
import { useTranslation } from "react-i18next";
import { PostTypeInProject } from "../../../../config/wordPressConfig";
import { pathForSingleUser } from "../../../helpers/serverSide/pageCreation/pathForSingleUser";
import HtmlFromLangFile from "../../01-atoms/HtmlFromLangFile/HtmlFromLangFile";
import { RVOAuthorCardProps } from "./RVOAuthorCardInterfaces";
import { useRVOAuthorCardStyles } from "./RVOAuthorCardStyles";

const RVOAuthorCard = (props: RVOAuthorCardProps): JSX.Element => {
  const classes = useRVOAuthorCardStyles();
  const { author, typographyProps, showLink, noLateralMargin } = props;
  const { t } = useTranslation();

  return (
    <Card
      className={clsx(
        classes.authorCard,
        !noLateralMargin && classes.authorCardMargin
      )}
      variant="outlined"
    >
      <CardContent className={classes.authorCardContent}>
        <Grid container={true} className={classes.authorCardContentGrid}>
          <Box className={classes.authorImageWrapper}>
            <Avatar
              className={classes.authorImage}
              alt={author?.name}
              src={author?.avatar?.url}
            />
          </Box>

          <Typography
            {...typographyProps}
            className={
              author?.additionalInfo?.jobTitle
                ? clsx(classes.authorName, classes.authorNameNoMarginBottom)
                : classes.authorName
            }
          >
            {author?.name}
          </Typography>

          {author?.additionalInfo?.jobTitle && (
            <Typography variant="body1" className={classes.authorJob}>
              {author?.additionalInfo?.jobTitle}
            </Typography>
          )}

          {author?.description && (
            <Typography
              variant="body1"
              className={classes.authorDescription}
              style={{ textAlign: "left" }}
            >
              <HtmlFromLangFile
                contentContainingHtml={author?.description.replace(
                  /\n/g,
                  "<br/>"
                )}
              />
            </Typography>
          )}

          {showLink && (
            <Button
              color="primary"
              variant="contained"
              to={pathForSingleUser(PostTypeInProject.WpPost, author?.name)}
              className={classes.authorLink}
            >
              {t("wissen.authorLink")}
            </Button>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RVOAuthorCard;
